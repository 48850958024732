import styled from "@emotion/styled"
import { colors, fonts } from "../theme"

export const FounderWrapper = styled.section`
  background-color: ${colors.gray};
  padding: 5%;
  position: relative;

  @media (min-width: 1280px) {
    padding: 10% 5%;
    min-height: 1080px;
  }
`

export const FounderWrapperLeft = styled.div`
  @media (min-width: 1280px) {
    max-width: 50%;
    margin-right: auto;
  }
`

export const FounderWrapperLeftTitle = styled.p`
  ${fonts.play};
  font-size: 2.5rem;
  color: ${colors.darkolive};
  margin-bottom: 4rem;
  margin-top: 2rem;

  @media (min-width: 768px) {
    font-size: 3.5rem;
    margin-top: 0;
  }
`

export const FounderWrapperLeftContent = styled.div`
  line-height: 2;
  font-size: 1.125rem;
  color: ${colors.army};
  position: relative;

  :last-of-type {
    margin-bottom: 4rem;
  }

  @media (min-width: 1280px) {
    padding-left: 2rem;

    :last-of-type {
      margin-bottom: 0;
    }

    &::before,
    &::after {
      color: ${colors.darkolive};
      ${fonts.play};
      font-size: 8rem;
      display: block;
      position: absolute;
      line-height: 1ch;
    }

    &::before {
      content: "“";
      left: -3rem;
      top: 0;
    }

    &::after {
      content: "”";
      right: -2rem;
      bottom: 0;
      transform: translateY(50%);
    }
  }
`

export const FounderWrapperRight = styled.div`
  @media (min-width: 1280px) {
    position: absolute;
    right: 4rem;
    top: -6rem;
    min-width: 37.5vw;
    min-height: 37.5vw;

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 2rem;
      right: 2rem;
      background-color: ${colors.tan};
    }
  }
`
