import React from "react"
import SEO from "../components/seo"

import AboutHero from "../components/hero/about-hero"
import Founder from "../components/founder/founder"
import Reviews from "../components/reviews/reviews"

const AboutPage = () => (
  <>
    <SEO title="About Us" />
    <AboutHero />
    <Founder />
    <Reviews />
  </>
)

export default AboutPage
