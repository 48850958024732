import React from "react"
import { StaticQuery, graphql } from "gatsby"
import {
  AboutHero,
  AboutHeroLeft,
  AboutHeroLeftTitle,
  AboutHeroLeftContent,
  AboutHeroRightBG,
} from "./about-hero-styles"
import PortableText from "@sanity/block-content-to-react"

export default () => (
  <StaticQuery
    query={graphql`
      {
        allSanityAbout {
          edges {
            node {
              heroTitle
              _rawHeroContent(resolveReferences: { maxDepth: 10 })
              heroBG {
                image {
                  asset {
                    fluid(maxWidth: 960) {
                      ...GatsbySanityImageFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const title = data.allSanityAbout.edges[0].node.heroTitle
      const content = data.allSanityAbout.edges[0].node._rawHeroContent
      const bg = data.allSanityAbout.edges[0].node.heroBG.image.asset.fluid
      return (
        <AboutHero>
          <AboutHeroLeft>
            <AboutHeroLeftTitle className="anim-in-up">
              {title}
            </AboutHeroLeftTitle>
            <AboutHeroLeftContent>
              <PortableText
                className="anim-in-up"
                blocks={content}
                serializers={{
                  types: {
                    ptImage: ({ node }) => (
                      <img src={node.image.asset.url} alt={node.alt} />
                    ),
                  },
                }}
              />
            </AboutHeroLeftContent>
          </AboutHeroLeft>
          <AboutHeroRightBG fluid={bg} />
        </AboutHero>
      )
    }}
  />
)
