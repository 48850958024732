import React from "react"
import { StaticQuery, graphql } from "gatsby"
import {
  FounderWrapper,
  FounderWrapperLeft,
  FounderWrapperLeftTitle,
  FounderWrapperLeftContent,
  FounderWrapperRight,
} from "./founder-styles"
import Img from "gatsby-image"
import PortableText from "@sanity/block-content-to-react"

export default () => (
  <StaticQuery
    query={graphql`
      {
        allSanityAbout {
          edges {
            node {
              founderTitle
              _rawFounderContent(resolveReferences: { maxDepth: 10 })
              founderImage {
                image {
                  asset {
                    fluid(maxWidth: 768) {
                      ...GatsbySanityImageFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const title = data.allSanityAbout.edges[0].node.founderTitle
      const content = data.allSanityAbout.edges[0].node._rawFounderContent
      const founderBg =
        data.allSanityAbout.edges[0].node.founderImage.image.asset.fluid

      return (
        <FounderWrapper>
          <FounderWrapperLeft>
            <FounderWrapperLeftTitle className="anim-in-up">
              {title}
            </FounderWrapperLeftTitle>
            <FounderWrapperLeftContent>
              <PortableText
                className="anim-in-up"
                blocks={content}
                serializers={{
                  types: {
                    ptImage: ({ node }) => (
                      <img src={node.image.asset.url} alt={node.alt} />
                    ),
                  },
                }}
              />
            </FounderWrapperLeftContent>
          </FounderWrapperLeft>
          <FounderWrapperRight>
            <Img className="anim-in-down" fluid={founderBg} />
          </FounderWrapperRight>
        </FounderWrapper>
      )
    }}
  />
)
