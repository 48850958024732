import styled from "@emotion/styled"
import { colors, fonts } from "../theme"
import Img from "gatsby-image"

export const AboutHero = styled.section`
  display: flex;
  flex-direction: row;
  background-color: ${colors.tan};
  min-height: 800px;
`

export const AboutHeroLeft = styled.div`
  padding: 6rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 1024px) {
    padding: 4rem;
    width: 50%;
  }
`

export const AboutHeroLeftTitle = styled.h1`
  ${fonts.play};
  font-size: 2.5rem;
  color: ${colors.darkolive};
  text-transform: capitalize;
  margin-bottom: 2rem;
`

export const AboutHeroLeftContent = styled.div`
  line-height: 1.75;

  @media (min-width: 1024px) {
    padding-left: 2rem;
  }

  img {
    margin: 4rem auto 0;

    @media (min-width: 768px) {
      max-width: 475px;
      width: 67%;
    }
  }
`

export const AboutHeroRightBG = styled(Img)`
  display: none;

  @media (min-width: 1024px) {
    width: 50%;
    display: block;
  }
`
